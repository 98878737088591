/* eslint-disable no-console */
import { BackendError } from 'client'
import { FirebaseApp, FirebaseError, FirebaseOptions, initializeApp } from 'firebase/app'
import {
  getAuth,
  User as FirebaseUser,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  verifyPasswordResetCode as firebaseVerifyPasswordResetCode,
  confirmPasswordReset,
  updatePassword as firebaseUpdatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  RecaptchaVerifier,
  PhoneAuthProvider,
  updatePhoneNumber,
  FacebookAuthProvider,
  Auth as FirebaseAuth,
  UserCredential,
  browserLocalPersistence,
  AuthErrorCodes as Codes,
  signInWithRedirect,
} from 'firebase/auth'
import { ref, getDownloadURL, getStorage, uploadBytes, FirebaseStorage } from 'firebase/storage'
import { logError } from 'utils/remote-logger'

export type { FirebaseUser }

type ErrorCode = (typeof Codes)[keyof typeof Codes]
type UserGetter<T> = (props: { user: FirebaseUser | null; token: string | null }) => Promise<T>
export class Auth<T> {
  private _storage?: FirebaseStorage = undefined
  app: FirebaseApp
  ready: Promise<{ token: string | null; user: FirebaseUser | null }>
  auth: FirebaseAuth
  getUser: UserGetter<T>

  constructor(
    options: FirebaseOptions,
    userGetter: UserGetter<T>,
    signUp: UserGetter<T> = userGetter,
  ) {
    this.app = initializeApp(options)
    // Initialize Firebase Authentication and get a reference to the service
    this.auth = getAuth(this.app)
    this.auth.setPersistence(browserLocalPersistence)
    this.ready = new Promise<{ token: string | null; user: FirebaseUser | null }>((resolve) => {
      const unsubscribe = this.auth.onAuthStateChanged(async (user: FirebaseUser | null) => {
        const token = user ? await user.getIdToken() : null
        resolve({ token, user })
        unsubscribe()
      })
    })
    this.getUser = userGetter
    this.ready.then(({ token, user }) => userGetter({ token, user })).catch(() => {})
  }

  private get storage() {
    // Initialize Cloud Storage and get a reference to the service
    if (!this._storage) this._storage = getStorage(this.app)
    return this._storage
  }

  async whenReady(): Promise<{ token: string | null; user: FirebaseUser | null }> {
    return await this.ready
  }

  getToken = async () => {
    await this.ready
    return (await this.auth.currentUser?.getIdToken()) ?? null
  }

  onTokenChange(
    callback: (data: { token: string | null; user: FirebaseUser | null }) => void,
  ): () => void {
    const unsubscribe = this.auth.onAuthStateChanged(async (user: FirebaseUser | null) => {
      const token = user ? await user.getIdToken() : null
      callback({ token, user })
    })
    return unsubscribe
  }

  private _phoneProvider?: PhoneAuthProvider = undefined
  private get phoneProvider() {
    if (!this._phoneProvider) this._phoneProvider = new PhoneAuthProvider(this.auth)
    return this._phoneProvider
  }

  private _googleProvider?: GoogleAuthProvider = undefined
  private get googleProvider() {
    if (!this._googleProvider) this._googleProvider = new GoogleAuthProvider()
    return this._googleProvider
  }

  private async processCredentials(result: UserCredential | null): Promise<T | null> {
    if (!result) return null
    const token = await result.user.getIdToken()
    if (!token) {
      this.auth.signOut()
      throw new Error(ERR.LOGIN_FAIL)
    }
    try {
      return await this.getUser({ token, user: result.user })
    } catch (cause) {
      console.error(cause)
      await this.auth.signOut()
      throw new Error(ERR.LOGIN_FAIL, { cause })
    }
  }

  /**
   * @return returns {@link User} on success, or `null` if the dialog was closed/cancelled
   */
  async signInWithGoogle(): Promise<T | null> {
    const result = await signInWithPopup(this.auth, this.googleProvider)
      .catch((error: FirebaseError) => {
        if (error.code === Codes.POPUP_BLOCKED) {
          return signInWithRedirect(this.auth, this.googleProvider)
        }
        throw error
      })
      .catch(catchIgnoredSignUpErrors)
      .catch(
        createFirebaseErrorHandler({
          message: (error) => getMessage(error.code) ?? ERR.LOGIN_FAIL,
        }),
      )
    return result ? await this.processCredentials(result) : null
  }

  private _facebookProvider?: FacebookAuthProvider = undefined
  private get facebookProvider() {
    if (!this._facebookProvider) this._facebookProvider = new FacebookAuthProvider()
    return this._facebookProvider
  }

  /**
   * @return returns {@link User} on success, or `null` if the dialog was closed/cancelled
   */
  async signInWithFacebook(): Promise<T | null> {
    const result = await signInWithPopup(this.auth, this.facebookProvider)
      .catch((error: FirebaseError) => {
        if (error.code === Codes.POPUP_BLOCKED) {
          return signInWithRedirect(this.auth, this.facebookProvider)
        }
        throw error
      })
      .catch(catchIgnoredSignUpErrors)
      .catch(
        createFirebaseErrorHandler({
          message: ({ code }) => getMessage(code) ?? ERR.LOGIN_FAIL,
        }),
      )
    return await this.processCredentials(result)
  }

  async signUpWithPassword({ email, password }: CredentialData): Promise<T | null> {
    const result = await createUserWithEmailAndPassword(this.auth, email, password).catch(
      createFirebaseErrorHandler({
        errors: (error) => {
          switch (error.code) {
            // auth/email-already-in-use    Thrown if there already exists an account with the given email address.
            // auth/invalid-email           Thrown if the email address is not valid.
            case Codes.EMAIL_EXISTS:
            case Codes.INVALID_EMAIL:
              return { email: getMessage(error.code) }

            // auth/weak-password           Thrown if the password is not strong enough.
            case Codes.WEAK_PASSWORD:
              return { password: getWeakPasswordMessage(error) ?? getMessage(error.code) }

            // auth/operation-not-allowed   Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
            default:
              return {}
          }
        },
      }),
    )
    return await this.processCredentials(result)
  }

  async loginWithPassword({ email, password }: CredentialData): Promise<T | null> {
    const result = await signInWithEmailAndPassword(this.auth, email, password).catch(
      createFirebaseErrorHandler({
        errors: (error) => {
          switch (error.code) {
            // auth/wrong-password     Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
            case Codes.INVALID_PASSWORD:
              return { password: getMessage(error.code) }

            // auth/user-not-found     Thrown if there is no user corresponding to the given email.
            // auth/user-disabled      Thrown if the user corresponding to the given email has been disabled.
            // auth/invalid-email      Thrown if the email address is not valid.
            case Codes.USER_DELETED:
            case Codes.USER_DISABLED:
            case Codes.INVALID_EMAIL:
              return { email: getMessage(error.code) }

            // auth/user-mismatch              Thrown if the credential given does not correspond to the user.
            // auth/invalid-credential         Thrown if the provider's credential is not valid. This can happen if it has already expired when calling link, or if it used invalid token(s). See the Firebase documentation for your provider, and make sure you pass in the correct parameters to the credential method.
            // auth/invalid-verification-code  Thrown if the credential is a credential and the verification code of the credential is not valid.
            // auth/invalid-verification-id    Thrown if the credential is a credential and the verification ID of the credential is not valid.
            default:
              return {}
          }
        },
      }),
    )
    return await this.processCredentials(result)
  }

  async signOut() {
    await firebaseSignOut(this.auth)
  }

  async sendPasswordResetEmail(email: string, route: string) {
    const url = new URL(route, window.location.origin)
    return await firebaseSendPasswordResetEmail(this.auth, email, {
      handleCodeInApp: true,
      url: url.href,
    }).catch(
      createFirebaseErrorHandler({
        message: () => ERR.PWD_RESET_FAIL,
        errors: (error: FirebaseError) => {
          switch (error.code) {
            // auth/invalid-email    Thrown if the email address is not valid.
            // auth/user-not-found
            case Codes.INVALID_EMAIL:
            case Codes.USER_DELETED:
              return { email: getMessage(error.code) }

            // auth/missing-android-pkg-name    An Android package name must be provided if the Android app is required to be installed.
            // auth/missing-continue-uri        A continue URL must be provided in the request.
            // auth/missing-ios-bundle-id       An iOS Bundle ID must be provided if an App Store ID is provided.
            // auth/invalid-continue-uri        The continue URL provided in the request is invalid.
            // auth/unauthorized-continue-uri   The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.
            default:
              return {}
          }
        },
      }),
    )
  }

  async verifyPasswordResetCode({
    oobCode,
    password,
  }: {
    oobCode: string
    password: string
  }): Promise<T | null> {
    const email = await firebaseVerifyPasswordResetCode(this.auth, oobCode).catch(
      createFirebaseErrorHandler({
        message: () => ERR.PWD_RESET_FAIL,
      }),
    )
    await confirmPasswordReset(this.auth, oobCode, password).catch(
      createFirebaseErrorHandler({
        message: () => ERR.PWD_RESET_FAIL,
        errors: (error: FirebaseError) => {
          switch (error.code) {
            case Codes.WEAK_PASSWORD:
              return { password: getWeakPasswordMessage(error) ?? getMessage(error.code) }
            default:
              return {}
          }
        },
      }),
    )
    return await this.loginWithPassword({ email, password })
  }

  async relogin({ password }: ReloginRequest): Promise<T | null> {
    const currentUser = this.auth.currentUser
    const email = this.auth.currentUser?.email
    if (!currentUser || !email) throw new BackendError(ERR.SIGNED_OUT)
    const credential = await EmailAuthProvider.credential(email, password)
    if (!credential) throw new BackendError(ERR.NO_CREDENTIALS)
    // https://firebase.google.com/docs/reference/js/v8/firebase.User#reauthenticatewithcredential
    const result = await reauthenticateWithCredential(currentUser, credential).catch(
      createFirebaseErrorHandler({
        errors: (error) => {
          switch (error.code) {
            // auth/wrong-password    Thrown if the password used in a firebase.auth.EmailAuthProvider.credential is not correct or when the user associated with the email does not have a password.
            case Codes.INVALID_PASSWORD:
              return { currentPassword: getMessage(error.code) }

            // auth/user-mismatch    Thrown if the credential given does not correspond to the user.
            // auth/user-not-found    Thrown if the credential given does not correspond to any existing user.
            // auth/invalid-credential    Thrown if the provider's credential is not valid. This can happen if it has already expired when calling link, or if it used invalid token(s). See the Firebase documentation for your provider, and make sure you pass in the correct parameters to the credential method.
            // auth/invalid-email    Thrown if the email used in a firebase.auth.EmailAuthProvider.credential is invalid.
            // auth/invalid-verification-code    Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification code of the credential is not valid.
            // auth/invalid-verification-id    Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification ID of the credential is not valid.
            default:
              return {}
          }
        },
      }),
    )
    return await this.processCredentials(result)
  }
  async updatePassword({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string
    newPassword: string
  }): Promise<void> {
    const currentUser = this.auth.currentUser
    const email = this.auth.currentUser?.email
    if (!currentUser) throw new Error(ERR.SIGNED_OUT)
    if (!email) throw new Error(ERR.SIGNED_OUT)
    const credential = await EmailAuthProvider.credential(email, currentPassword)
    if (!credential) throw new Error(ERR.NO_CREDENTIALS)
    await reauthenticateWithCredential(currentUser, credential).catch(
      createFirebaseErrorHandler({
        errors: (error) => {
          switch (error.code) {
            // auth/wrong-password    Thrown if the password used in a firebase.auth.EmailAuthProvider.credential is not correct or when the user associated with the email does not have a password.
            case Codes.INVALID_PASSWORD:
              return { currentPassword: getMessage(error.code) }

            // auth/user-mismatch    Thrown if the credential given does not correspond to the user.
            // auth/user-not-found    Thrown if the credential given does not correspond to any existing user.
            // auth/invalid-credential    Thrown if the provider's credential is not valid. This can happen if it has already expired when calling link, or if it used invalid token(s). See the Firebase documentation for your provider, and make sure you pass in the correct parameters to the credential method.
            // auth/invalid-email    Thrown if the email used in a firebase.auth.EmailAuthProvider.credential is invalid.
            // auth/invalid-verification-code    Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification code of the credential is not valid.
            // auth/invalid-verification-id    Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification ID of the credential is not valid.
            default:
              return {}
          }
        },
      }),
    )
    await firebaseUpdatePassword(currentUser, newPassword).catch(
      createFirebaseErrorHandler({
        errors: (error) => {
          switch (error.code) {
            // auth/weak-password    Thrown if the password is not strong enough.
            case Codes.WEAK_PASSWORD:
              return { newPassword: getWeakPasswordMessage(error) ?? getMessage(error.code) }

            // auth/requires-recent-login  Thrown if the user's last sign-in time does not meet the security threshold. Use firebase.User.reauthenticateWithCredential to resolve. This does not apply if the user is anonymous.
            default:
              return {}
          }
        },
      }),
    )
  }

  createVerifier(element: string | HTMLElement): RecaptchaVerifier {
    return new RecaptchaVerifier(element, { size: 'invisible', callback() {} }, this.auth)
  }
  async verifyPhoneNumber({ phone_number, recaptchaVerifier }: PhoneVerification): Promise<string> {
    const user = this.auth.currentUser
    if (!user) throw new Error(ERR.SIGNED_OUT)

    const verificationId = await this.phoneProvider
      .verifyPhoneNumber({ phoneNumber: phone_number }, recaptchaVerifier)
      .catch(
        createFirebaseErrorHandler({
          errors: (error) => {
            switch (error.code) {
              // auth/invalid-phone-number    Thrown if the phone number has an invalid format.
              // auth/missing-phone-number    Thrown if the phone number is missing.
              case Codes.INVALID_PHONE_NUMBER:
              case Codes.MISSING_PHONE_NUMBER:
                return { phone_number: getMessage(error.code) }

              // auth/quota-exceeded    Thrown if the SMS quota for the Firebase project has been exceeded.
              // auth/user-disabled    Thrown if the user corresponding to the given phone number has been disabled.
              // auth/captcha-check-failed    Thrown if the reCAPTCHA response token was invalid, expired, or if this method was called from a non-whitelisted domain.
              // auth/maximum-second-factor-count-exceeded    Thrown if The maximum allowed number of second factors on a user has been exceeded.
              // auth/second-factor-already-in-use    Thrown if the second factor is already enrolled on this account.
              // auth/unsupported-first-factor    Thrown if the first factor being used to sign in is not supported.
              // auth/unverified-email    Thrown if the email of the account is not verified.
              default:
                return {}
            }
          },
        }),
      )
    return verificationId
  }

  /** https://firebase.google.com/docs/reference/js/v8/firebase.User#updatephonenumber */
  async confirmPhoneNumber({ verificationId, verificationCode }: PhoneNumberConfirmation) {
    const user = this.auth.currentUser
    if (!user) throw new Error(ERR.SIGNED_OUT)
    const credential = PhoneAuthProvider.credential(verificationId, verificationCode)
    await updatePhoneNumber(user, credential).catch(
      createFirebaseErrorHandler({
        message: (error) => getMessage(error.code) ?? ERR.PHONE_VERIFICATION_FAIL,
        errors: (error) => {
          switch (error.code) {
            // auth/invalid-verification-code   Thrown if the verification code of the credential is not valid.
            case Codes.INVALID_CODE:
              return { verificationCode: getMessage(error.code) }

            // auth/invalid-verification-id     Thrown if the verification ID of the credential is not valid.
            default:
              return {}
          }
        },
      }),
    )
  }

  async uploadPhotos(file: File) {
    const storageRef = ref(this.storage, 'photos/' + file.name)
    await uploadBytes(storageRef, file)
    const downloadURL = await getDownloadURL(storageRef)
    if (!downloadURL) {
      throw new Error(ERR.UPLOAD_FAIL)
    }
    return downloadURL
  }
}

type ErrorsResolver = (error: FirebaseError) => {}
type MessageResolver = (error: FirebaseError) => string
const createFirebaseErrorHandler =
  ({ errors, message }: { errors?: ErrorsResolver; message?: MessageResolver } = {}) =>
  (error: FirebaseError) => {
    const text = message ? message(error) : getMessage(error.code) ?? ERR.UNEXPECTED
    const fieldErrors = errors ? errors(error) : {}
    logError(`${text}\n${error.code}`, { ...error })
    const err = new BackendError(text, { cause: error }, fieldErrors)
    err.message = text
    throw err
  }

export interface LoginResult {
  user: FirebaseUser
  token: string
}

export interface CredentialData {
  email: string
  password: string
}
export interface ReloginRequest {
  password: string
}

export interface PhoneVerification {
  phone_number: string
  recaptchaVerifier: RecaptchaVerifier
}

export interface PhoneNumberConfirmation {
  verificationId: string
  verificationCode: string
}

const IGNORE_SIGN_UP_ERRORS = [Codes.POPUP_CLOSED_BY_USER, Codes.USER_CANCELLED]
type IgnoredCode = (typeof IGNORE_SIGN_UP_ERRORS)[number]

const isIgnoredCode = (code: string): code is IgnoredCode =>
  IGNORE_SIGN_UP_ERRORS.includes(code as IgnoredCode)

function catchIgnoredSignUpErrors(error: FirebaseError) {
  if (isIgnoredCode(error.code)) return null
  throw error
}

function getMessage(code: string) {
  return isIgnoredCode(code as ErrorCode) ? undefined : ERR[code as keyof typeof ERR]
}

function getWeakPasswordMessage(error: FirebaseError) {
  if (error.message.startsWith('Firebase:')) {
    return error.message.replace('Firebase: ', '').replace(' (auth/weak-password)', '')
  }
  return undefined
}

const ERR = {
  [Codes.EMAIL_EXISTS]: 'There already exists an account with the given email address.',
  [Codes.INVALID_APP_CREDENTIAL]: 'The app credential is invalid.',
  [Codes.EXPIRED_OOB_CODE]: 'The code has been expired.',
  [Codes.INVALID_CODE]: 'Invalid verification code.',
  [Codes.INVALID_EMAIL]: 'Email address is not valid.',
  [Codes.INVALID_OOB_CODE]: 'Invalid code.',
  [Codes.INVALID_PASSWORD]: 'Wrong password.',
  [Codes.NEED_CONFIRMATION]: 'There already exists an account with the given email address.',
  [Codes.USER_DELETED]: 'There is no user corresponding to the given email.',
  [Codes.USER_DISABLED]: 'The user corresponding to the given email has been disabled.',
  [Codes.WEAK_PASSWORD]: 'The password is not strong enough.',
  [Codes.CAPTCHA_CHECK_FAILED]: 'Invalid or expired reCAPTCHA.',
  [Codes.QUOTA_EXCEEDED]: 'The SMS quota has been exceeded.',
  [Codes.MISSING_PHONE_NUMBER]: 'The phone number is missing.',
  [Codes.INVALID_PHONE_NUMBER]: 'The phone number has an invalid format.',
  [Codes.TOO_MANY_ATTEMPTS_TRY_LATER]: 'Too many attempts. Try again later.',
  LOGIN_FAIL: 'Failed to login.',
  NO_CREDENTIALS: 'An error occured: missing credentials.',
  PHONE_VERIFICATION_FAIL: 'Phone number verification failed.',
  PWD_RESET_FAIL: 'Failed to reset password.',
  SIGNED_OUT: 'No user is signed in.',
  UNEXPECTED: 'An unexpected error occured.',
  UPLOAD_FAIL: 'Failed to upload photos.',
} as const
